import React from 'react';
import * as S from './LiverColumnWrapper.styles';
import * as SW from '../LiverWrapper/LiverWrapper.styles';

interface ColumnProps {
  title: string;
  body: string;
}

export default ({
  columns,
  color = '#2e3191',
  bgColor = '#fff',
  headerStyles = '',
  background = '',
  isOverlay = false,
  isTight = false,
}: {
  columns: ColumnProps[];
  color?: string;
  bgColor?: string;
  headerStyles?: string;
  background?: string;
  isOverlay?: boolean;
  isTight?: boolean;
}) => (
  <S.Wrapper bgColor={bgColor} background={isOverlay ? '' : background}>
    {isOverlay && <SW.OverlayBackround background={background} isTight={isTight} />}
    <S.InnerWrapper>
      {columns.map(({ title, body }: ColumnProps, index) => (
        <S.Column key={index}>
          <S.Header headerStyles={headerStyles} color={color}>
            {title}
          </S.Header>
          <S.Text>{body}</S.Text>
        </S.Column>
      ))}
    </S.InnerWrapper>
  </S.Wrapper>
);
