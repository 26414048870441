import styled from 'styled-components';

export const Wrapper = styled.section<any>`
  background-color: ${(props) => props.bgColor};
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  ${(props) =>
    props.isReversed &&
    `
    flex-direction: row-reverse;
  `}
  background-image: url(${(props) => props.background});
  background-size: contain;
  z-index: 1;
  position: relative;
`;

export const BodyWrapper = styled.div`
  min-width: 1150px;
  flex-grow: 1;
  max-width: 100%;
  display: flex;
  justify-content: center;

  @media (max-width: 1150px) {
    padding-right: 0;
    min-width: initial;
  }

`;

export const Body = styled.div<any>`
  max-width: 898px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  @media (min-width: 1200px) {
    margin: ${(props) => props.side === 'left' ? '8% 0 0 15%;' : '8% 15% 0 0%;'};
  }
`;

export const ImageContainer = styled.div`
  min-height: 1000px;
  flex-grow: 1;
  overflow: hidden;
  height: 100vh;
  object-position: right;
  object-fit: cover;

  @media (max-width: 1150px) {
    display: none;
  }
`;

export const SideImage = styled.img<any>`
  min-height: 1000px;
  flex-grow: 1;
  height: 100vh;
  object-fit: cover;
  object-position: left;
  z-index: 0;

  @media (max-width: 1000px) {
    display: none;
  }
`;

export const OverlayBackround = styled.div<any>`
  width: 100%;
  height: 100%;
  position: absolute;
  background-size: cover;
  z-index: 0;
  background-image: url(${(props) => props.background});
  opacity: 0.1;

  @media (min-width: 600px) {
    opacity: 0.2;
  }

  @media (min-width: 1350px) {
    opacity: ${(props) => props.isTight ? 0.4 : 1};
  }

  @media (min-width: 1650px) {
    opacity: 1;
  }
`;
