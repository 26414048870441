import React from 'react';
import LiverBG6 from '../../assets/liver/LiverBG6.png';
import LiverColumnWrapper from '../LiverColumnWrapper/LiverColumnWrapper';

const data = [
  {
    title: 'Marskość wątroby - bezbolesne zagrożenie',
    body: `
    Chociaż nazwa tej jednostki chorobowej jest powszechnie znana, wielu pacjentów, nawet w zaawansowanym stadium, nie podejrzewa, że może cierpieć na marskość wątroby. Nic dziwnego - u niemal 40% chorych marskość przebiega na początku bezobjawowo i wykrywana jest przypadkowo. Diagnostykę utrudnia fakt, że objawy marskości, do których należą osłabienie, biegunki, żółtaczka i zmiany naczyniowe, dotyczą różnych części organizmu i pozornie nie są ze sobą związane. Nieleczona, marskość wątroby prowadzi w perspektywie kilku lat do zgonu na skutek zaburzeń w funkcjonowaniu organów lub raka, w którego często się rozwija.
`,
  },
  {
    title: 'Wyzwania w diagnostyce nowotworów wątroby',
    body: `
    Co piąty mężczyzna i co siódma kobieta, którzy umierają na świecie z powodu nowotworów, cierpią na raka wątroby. Ich rokowania są zazwyczaj złe. Czas podwojenia się masy guza to zaledwie 4 do 6 miesięcy. W momencie pojawienia się pełnoobjawowej marskości często za późno jest na leczenie - lekarze mogą skupiać się jedynie na łagodzeniu skutków choroby. Technologia Liver AI znacznie skraca czas diagnozowania i umożliwia wykrywanie niepokojących zmian już na bardzo wczesnym etapie rozwoju choroby. Dzięki zastosowaniu inteligentnych algorytmów do rozpoznawania i obrazowania zmian martwiczych w wątrobie, lekarz radiolog otrzymuje precyzyjne wsparcie diagnostyczne i informacje niezbędne do planowania kolejnych etapów terapii.
`,
  },
];

export default () => (
  <LiverColumnWrapper isTight isOverlay background={LiverBG6} bgColor='#F5ECEA' color='#000' columns={data} />
);
