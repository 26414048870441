import styled from 'styled-components';

export const Wrapper = styled.div`
  margin: auto 0;
  max-width: 855px;
  width: 100%;
  padding: 32px 0;

  @media (max-width: 400px) {
    max-width: 300px;
    margin: 0 auto;
  }

  @media (max-width: 948px) {
    background-position: right;
  }
`;

export const Header = styled.h2`
  font-family: "Now Bold";
  font-weight: 900;
  line-height: 1.2;
  color: #000;
  margin: 20px 0;
  text-align: left;
  font-size: 28px;

  @media (min-width: 500px) {
    font-size: 44px;
    margin: 0 auto 80px;
    margin-left: 0;
  }

  @media (min-width: 800px) {
    margin: 15% 0 9%;
  }

  @media (min-width: 1210px) {
    text-align: left;
    margin: 5% 0 9%;
    font-size: 56px;
  }

  @media (min-width: 1700px) {
    margin: 15% 0 9%;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-family: Now;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.65;
  text-align: left;
  color: #747474;

  @media (min-width: 500px) {
    font-size: 26px;
  }
`;
