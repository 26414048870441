import * as React from "react"
import Liver1 from '../components/Liver1/Liver1';
import Liver2 from '../components/Liver2/Liver2';
import Liver3 from '../components/Liver3/Liver3';
import Liver4 from '../components/Liver4/Liver4';
import Liver5 from '../components/Liver5/Liver5';
import Liver6 from '../components/Liver6/Liver6';
import Liver7 from '../components/Liver7/Liver7';
import Liver8 from '../components/Liver8/Liver8';
import '../components/layout.css';

const IndexPage = () => (
  <>
    <Liver1 />
    <Liver2 />
    <Liver3 />
    <Liver4 />
    <Liver5 />
    <Liver6 />
    <Liver7 />
    <Liver8 />
  </>
)

export default IndexPage
