import React from 'react';
import LiverWrapper from '../LiverWrapper/LiverWrapper';
import stetho from '../../assets/stetho.jpg';
import LiverBG3 from '../../assets/liver/LiverBG3.png';
import * as S from './Liver4.styles';

export default () => (
  <LiverWrapper side='right' isReversed isOverlay background={LiverBG3} img={stetho} bgColor="#F5ECEA">
    <S.Wrapper>
      <S.Header>Zmniejszona dawka kontrastu</S.Header>
      <S.Text>
        Środek kontrastujący to substancja podawana podczas badań diagnostycznych, takich jak
        rezonans magnetyczny czy tomografia. Wytwarzany z soli baru i związków jodu, kontrast
        wchodzi w reakcję z promieniami rentgenowskimi i mimo ostrożnego dawkowania nie jest
        obojętny dla zdrowia. Obok skutków ubocznych, takich jak zawroty głowy czy nudności,
        kontrast może wywoływać wstrząs anafilaktyczny czy poważne zaburzenia pracy nerek. Z tego
        powodu jego dawki należy ograniczać do minimum, zwłaszcza wśród pacjentów starszych oraz
        dzieci do 10 roku życia. Zastosowanie technologii Liver AI w diagnostyce chorób wątroby
        pozwala na zmniejszenie dawki środka kontrastującego nawet o 30%.
      </S.Text>
    </S.Wrapper>
  </LiverWrapper>
);
