import React from 'react';
import LiverWrapper from '../LiverWrapper/LiverWrapper';
import stetho from '../../assets/stetho.jpg';
import LiverBG4 from '../../assets/liver/LiverBG4.png';
import * as S from '../Liver4/Liver4.styles';

export default () => (
  <LiverWrapper background={LiverBG4} isOverlay img={stetho}>
    <S.Wrapper>
      <S.Header>Krótki czas naświetlania</S.Header>
      <S.Text>
        Moduły sztucznej inteligencji systemu Liver AI dokonują szczegółowej segmentacji obrazu
        wątroby w czasie znacznie szybszym niż wymagany na tradycyjne oględziny. Zmniejsza to okres
        naświetlania promieniami rentgenowskimi a co za tym idzie ryzyko powikłań i obciążenia dla
        organizmu pacjenta.
      </S.Text>
    </S.Wrapper>
  </LiverWrapper>
);
