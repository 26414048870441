import React from 'react';
import LiverWrapper from '../LiverWrapper/LiverWrapper';
import liverSide from '../../assets/liver/liverSide.png';
import LiverBG4 from '../../assets/liver/LiverBG4.png';
import * as S from './Liver3.styles';

export default () => (
  <LiverWrapper
    isOverlay
    background={LiverBG4}
    img={liverSide}
  >
    <S.Wrapper>
      <S.Header>Diagnostyka wspomagana modułami AI</S.Header>
      <S.Text>
        Badanie aparatem do tomografii komputerowej jest czasochłonne i&nbsp;obarczone pewnym
        ryzykiem powikłań. Wykorzystanie sztucznej inteligencji usprawnia pracę lekarza diagnosty
        i&nbsp;pozwala uzyskać dokładniejszy obraz przy znacznie mniejszym obciążeniu pacjenta.
        Technologia Liver AI umożliwia wykonanie szczegółowego obrazu 3D wątroby na podstawie danych
        z&nbsp;tomografii komputerowej. Przetwarzany przez moduł AI obraz jest segmentowany
        i&nbsp;wyostrzany, aby możliwe było precyzyjne wskazanie miejsc zwłóknienia wątroby.
      </S.Text>
    </S.Wrapper>
  </LiverWrapper>
);
