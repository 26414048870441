import React from 'react';
import liverLogo from '../../assets/liver/liverLogo.svg';
import liver from '../../assets/liver/liver.png';
import * as S from './Liver1.styles';

export default () => (
  <S.Wrapper>
    <S.InnerWrapper>
      <S.HeadWrapper>
        <S.HeadBody>
          <S.HeaderRow>
            
          </S.HeaderRow>
          <S.HeaderRow>
           
          </S.HeaderRow>
        </S.HeadBody>
      </S.HeadWrapper>
      <S.NavBar>
        <S.Logo src={liverLogo} />
      </S.NavBar>
      <S.Body>
        <S.MainPhoto src={liver} />
        <S.Column>
          <S.Header>Nieinwazyjna diagnostyka marskości wątroby</S.Header>
        </S.Column>
      </S.Body>
    </S.InnerWrapper>
  </S.Wrapper>
);
