import { GoMail } from 'react-icons/go';
import { MdLocalPhone } from 'react-icons/md';
import styled from 'styled-components';
import LiverBG1 from '../../assets/liver/LiverBG1.png';

export const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;

  @media (max-width: 1000px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }

  @media (min-width: 1150px) {
    background-image: url(${LiverBG1});
  }
`;

export const InnerWrapper = styled.div`
  min-height: 100vh;
  width: 100%;
  height: 100%;
`;

export const HeadWrapper = styled.header`
  height: 84px;
  width: 100%;
  background-color: #9d3422;
  padding: 0 16px;
  box-sizing: border-box;

  @media (min-width: 400px) {
    margin-bottom: 37px;
  }
`;

export const HeadBody = styled.div`
  max-width: 1400px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  height: 100%;
`;

export const HeaderRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 354px;
  width: 100%;
`;

const Icon = `
  color: #f3f4f2; 
  width: 26px; 
  height: 26px; 
  margin-right: 8px;
`;

export const PhoneIcon = styled(MdLocalPhone)`
  ${Icon}
`;

export const MailIcon = styled(GoMail)`
  ${Icon}
`;

export const Description = styled.a`
  font-family: Now;
  font-size: 12px;
  font-weight: bold;
  color: #f3f4f2;
  margin: 0;
  text-decoration: none;

  @media (min-width: 400px) {
    font-size: 14px;
  }
`;

export const NavBar = styled.nav`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 23px 16px;
  box-sizing: border-box;
  max-width: 100%;
  justify-content: center;

  @media (min-width: 400px) {
    max-width: 1450px;
    justify-content: initial;
  }

  @media (min-width: 1200px){
    margin: 0 auto;
  }
`;

export const Logo = styled.img`
  object-fit: contain;
  width: auto;
  max-height: 103px;
  height: 100%;
`;

export const LinksWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledLink = styled.a`
  font-family: Causten;
  font-size: 19px;
  font-weight: bold;
  color: #404041;
  text-transform: uppercase;
  text-decoration: none;
  margin: 0 15px;
`;

export const Body = styled.div`
  max-width: 1475px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  padding: 0 16px;
  box-sizing: border-box;
  align-items: center;
  height: calc(100% - 260px);

  @media (max-width: 1220px) {
    flex-wrap: wrap;
    justify-content: space-evenly;
  }
`;

export const MainPhoto = styled.img`
  object-position: center;
  border-radius: 100%;
  flex-grow: 1;
  max-width: 60vw;
  min-height: 60vw;
  max-height: 60vw;
  object-fit: contain;

  @media (min-width: 400px) {
    object-fit: cover;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
  }

  @media (min-width: 600px) {
    object-fit: cover;
    max-width: 400px;
    min-height: 400px;
    max-height: 400px;
  }

  @media (min-width: 1316px) {
    max-width: 500px;
    min-height: 500px;
    max-height: 500px;
  }

  @media (min-width: 1382px) {
    max-width: 565px;
    max-height: 565px;
    min-height: 565px;
    object-position: bottom;
  }
`;

export const Column = styled.div`
  margin: auto;
  max-width: 764px;
  width: 100%;
`;

export const Header = styled.h1`
  font-family: "Now Bold";
  font-weight: 900;
  line-height: 1.2;
  color: #404041;
  text-align: left;
  max-width: 90%;
  margin: 0 auto;
  font-size: 28px;
  margin-top: 20px;

  @media (min-width: 400px) {
    font-size: 36px;
    margin-top: 40px;
  }

  @media (min-width: 600px) {
    font-size: 51px;
    text-align: center;
    max-width: initial;
  }

  @media(min-width: 1194px) {
    text-align: left;
    font-size: 67px;
    margin-left: 40px;
    margin-top: 40px;
  }
`;
