import React from 'react';
import * as S from './LiverWrapper.styles';

export default ({
  img,
  children,
  isReversed = false,
  bgColor = '#fff',
  imgStyles = '',
  bodyStyles = '',
  background,
  id,
  isOverlay = false,
  side = 'left'
}: {
  bgColor?: string;
  isReversed?: boolean;
  img: string;
  children: React.ReactChild;
  imgStyles?: string;
  bodyStyles?: string;
  background: string;
  id?: string;
  isOverlay?: boolean;
  side?: string;
}) => (
  <S.Wrapper bgColor={bgColor} background={isOverlay ? '' : background} id={id} isReversed={isReversed}>
    {isOverlay && <S.OverlayBackround background={background} />}
    <S.BodyWrapper>
      <S.Body side={side} bodyStyles={bodyStyles}>
        {children}
      </S.Body>
    </S.BodyWrapper>
    <S.ImageContainer>
      <S.SideImage src={img} imgStyles={imgStyles} />
    </S.ImageContainer>
  </S.Wrapper>
);
