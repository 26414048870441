import React from 'react';
import LiverWrapper from '../LiverWrapper/LiverWrapper';
import stetho from '../../assets/stetho.jpg';
import LiverBG4 from '../../assets/liver/LiverBG4.png';
import * as S from '../Liver4/Liver4.styles';

export default () => (
  <LiverWrapper
    background={LiverBG4}
    isOverlay
    img={stetho}
  >
    <S.Wrapper>
      <S.Header>
        Marskość wątroby <br />w liczbach
      </S.Header>
      <S.Text>
        Około <S.BoldedRed>1,5kg</S.BoldedRed> waży wątroba dorosłego człowieka. W organie tym
        zachodzi blisko <S.BoldedRed>5 000</S.BoldedRed> różnych reakcji chemicznych{' '}
        <S.BoldedRed>4 do 10%</S.BoldedRed> populacji choruje na marskość wątroby jest to{' '}
        <S.BoldedRed>5</S.BoldedRed> pod względem liczebności przyczyna zgonów w Polsce{' '}
        <S.BoldedRed>2 razy</S.BoldedRed> częściej chorują mężczyźni niż kobiety jedynie{' '}
        <S.BoldedRed>25%</S.BoldedRed> pacjentów przeżywa powyżej{' '}
        <S.BoldedRed>5&nbsp;lat</S.BoldedRed> od rozpoznania choroby wczesne stadium choroby{''}
        przebiega bezobjawowo u <S.BoldedRed>40% pacjentów</S.BoldedRed>
      </S.Text>
    </S.Wrapper>
  </LiverWrapper>
);
