import styled from 'styled-components';

export const Wrapper = styled.section`
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-color: #f5ecea;
`;

export const InnerWrapper = styled.div`
  max-width: 1360px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  margin-top: 70px;
  min-height: 830px;
  display: flex;
  flex-direction: column;
`;

export const Header = styled.h2`
  margin: 0 0 41px;
  width: 100%;
  font-weight: 500;
  font-family: 'Causten Bold';
  line-height: 0.92;
  text-align: center;
  color: #000;
  font-size: 28px;

  @media (min-width: 500px) {
    font-size: 59px;
  }
`;

export const Text = styled.p`
  width: 100%;
  font-family: Now;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
  line-height: 43px;
  margin: 0;
  font-size: 21px;

  @media (min-width: 500px) {
    font-size: 26px;
  }
`;

export const TextStrong = styled.p`
  width: 100%;
  font-family: 'Now Bold';
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.65;
  letter-spacing: normal;
  text-align: center;
  color: #747474;
  line-height: 43px;
  margin: 0;
  font-size: 21px;

  @media (min-width: 500px) {
    font-size: 26px;
  }
`

export const ContactDataWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: auto;
  flex-wrap: wrap;
  padding: 32px 0;
`;

export const Footer = styled.footer`
  width: 100%;
  min-height: 178px;
  background-color: #fff;
  margin: auto 0 0;
`;

export const FooterBody = styled.div`
  box-sizing: border-box;
  max-width: 1420px;
  width: 100%;
  padding: 50px 16px;
  width: 100%;
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
`;

export const CopyRight = styled.p`
  font-family: Now;
  font-size: 19px;
  text-align: center;
  color: black;
  max-width: 402px;
  width: 100%;
  margin: 27px 0;
`;

export const ItemWrapper = styled.div`
  display: flex;
  min-width: 182px;
  margin-bottom: 18px;

  @media(max-width: 880px) {
    min-width: 100%;
    padding-left: 30%;
  }

  @media(max-width: 400px) {
    padding-left: 20px;
  }
`;

export const ItemIcon = styled.img`
  width: 26px;
  height: 26px;
  margin-right: 13px;
  color: #9d3422;
`;

export const ItemBody = styled.div``;

export const ItemHeader = styled.h6`
  font-family: Now;
  font-size: 20px;
  font-weight: bold;
  color: #224750;
  margin: 0;
`;

export const ItemText = styled.p`
  margin: 12px 0 0;
  font-family: Now;
  font-size: 18px;
  color: #707070;
`;

export const EuropeFunds = styled.img`
  object-fit: contain;
`;

export const FoundsWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-wrap: wrap;
  flex-grow: 2;
`;
