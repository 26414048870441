import React from 'react';
import { FaMapMarkerAlt } from 'react-icons/fa';
import { GoMail } from 'react-icons/go';
import { MdLocalPhone } from 'react-icons/md';
import ContactItem from './ContactItem';
import logotype0 from '../../assets/logotype0.png';
import logotype1 from '../../assets/logotype1.png';
import logotype2 from '../../assets/logotype2.png';
import logotype3 from '../../assets/logotype3.png';

import * as S from './Liver8.styles';

const contactItems = [
 /* {
    icon: (
      <MdLocalPhone
        style={{ color: '#9D3422', width: '26px', height: '26px', marginRight: '13px' }}
      />
    ),
    header: 'Telefon',
    text: ['(+48) 796 686 376', '(+48) 796 686 376'],
  }, */
  {
    icon: (
      <FaMapMarkerAlt
        style={{ color: '#9D3422', width: '26px', height: '26px', marginRight: '13px' }}
      />
    ),
    header: 'Adres',
    text: ['LiverAI Sp. z o.o.', 'Pl. Kilińskiego 2, 35-005 Rzeszów', 'NIP: 517-040-55-14', 'KRS: 0000831959'],
  },
  {
    icon: (
      <GoMail style={{ color: '#9D3422', width: '26px', height: '26px', marginRight: '13px' }} />
    ),
    header: 'E-mail',
    text: ['pawel.wojtkowiak@liverai.pl'],
  },
];

export default () => (
  <S.Wrapper>
    <S.InnerWrapper>
      <S.Header>Kontakt</S.Header>
      <S.Text>
        Obecnie projekt realizowany jest przez fundusz inwestycyjny Polish Venture Fund przy
        współfinansowaniu Narodowego Centrum Badań i Rozwoju w ramach programu Bridge Alfa.
      </S.Text>
      <br />
      <S.TextStrong>
        Szukamy inwestorów gotowych zainwestować działalność spółki w kontekście wejścia na rynek
        oprogramowania medycznego
      </S.TextStrong>
	  <br />
      <S.Text>
        Kontakt inwestycyjny: www.polishventurefund.pl
      </S.Text>
      <S.ContactDataWrapper>
        {contactItems.map((item: any, index) => (
          <ContactItem key={index} {...item} />
        ))}
      </S.ContactDataWrapper>
    </S.InnerWrapper>
    <S.Footer>
      <S.FooterBody>
        <S.CopyRight>
          <strong>© 2021 LiverAI</strong>
        </S.CopyRight>
        <S.FoundsWrapper>
        <S.EuropeFunds src={logotype0} />
          <S.EuropeFunds src={logotype1} />
          <S.EuropeFunds src={logotype2} />
          <S.EuropeFunds src={logotype3} />
        </S.FoundsWrapper>
      </S.FooterBody>
    </S.Footer>
  </S.Wrapper>
);
