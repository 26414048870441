import styled from 'styled-components';

export const Wrapper = styled.section<any>`
  background-color: ${(props) => props.bgColor};
  width: 100%;
  min-height: 100vh;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  background-image: url(${(props) => props.background});
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
`;

export const InnerWrapper = styled.div`
  max-width: 1384px;
  padding: 0 16px;
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 18%;
  margin-bottom: 40px;

  @media (max-width: 1090px) {
    flex-direction: column;
  }
`;

export const Column = styled.div`
  max-width: 644px;
  margin: 0 8px;
  width: 100%;
  display: flex;
  flex-direction: column;

  @media (max-width: 400px) {
    max-width: 300px;
    margin: 0 auto;
  }

  @media (max-width: 1090px) {
    margin: 0 auto 79px;
  }
`;

export const Header = styled.h2<any>`
  margin: 0 0 5%;
  width: 100%;
  min-height: 96px;
  z-index: 1;
  font-family: "Now Bold";
  line-height: 1.2;
  color: ${(props) => props.color};
  
  @media (min-width: 1700px) {
    margin: 0 0 11.5%;
    font-size: 36px;
  }
`;

export const Text = styled.p`
  font-family: "Now";
  font-size: 21px;
  line-height: 1.59;
  color: #5e5e5e;
  width: 675px;
  width: 100%;

  @media (max-width: 400px) {
    max-width: 300px;
    margin: 0 auto;
    font-size: 18px;
  }
`;
