import React from 'react';
import liverFloating from '../../assets/liver/liverFloating.png';
import * as S from './Liver2.styles';

export default () => (
  <S.Wrapper>
  <S.OverlayBackround />
      <S.ColumnLeftWrapper>
        <S.MainImage src={liverFloating} />
      </S.ColumnLeftWrapper>
      <S.ColumnRightWrapper>
        <S.Header>Dokładniej i&nbsp;bezpieczniej</S.Header>
        <S.Text>
          <strong>Badanie Liver AI</strong> to technologia, która umożliwia precyzyjną
          i&nbsp;bezpieczną dla pacjenta diagnostykę marskości wątroby i&nbsp;towarzyszących jej
          zmian onkologicznych.
        </S.Text>
      </S.ColumnRightWrapper>
  </S.Wrapper>
);
