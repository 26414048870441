import styled from 'styled-components';
import LiverBG2 from '../../assets/liver/LiverBG2.png';

export const OverlayBackround = styled.div`
  background-image: url(${LiverBG2});
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
  background-position-x: 950px;
  background-repeat: no-repeat;
  background-position: right;

  @media (max-width: 1215px) {
    opacity: 0.4;
  }
`;

export const Wrapper = styled.section`
  min-height: 100vh;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  box-sizing: border-box;
  flex-direction: row-reverse;
  justify-content: space-evenly;

  @media (max-width: 1210px) {
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
  }
`;

export const ColumnLeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 80%;
  align-items: center;
  flex-grow: 0;

  @media (min-width: 780px) {
    max-width: 38%;
  }

  @media (min-width: 1211px) {
    flex-grow: 1;
  }
`;
  
export const ColumnRightWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  max-width: 72%;
  padding-left: 0;

  @media (min-width: 1000px) {
    padding-left: 16px;
    max-width: 77%;
  }

  @media (min-width: 1211px) {
    max-width: 43%;
    margin: 0;
    margin-left: auto;
  }

  @media (min-width: 1550px) {
    max-width: 48%;
    padding-left: 16px;
  }
`;

export const MainImage = styled.img`
  object-position: center;
  border-radius: 100%;
  flex-grow: 1;
  min-height: 180px;
  max-height: 180px;
  object-fit: contain;
  z-index: 1;
  padding: 20px;

  @media (min-width: 400px) {
    object-fit: cover;
    max-width: 300px;
    min-height: 300px;
    max-height: 300px;
  }

  @media (min-width: 400px) {
    object-fit: cover;
    max-width: 40vw;
    min-height: 40vw;
    max-height: 40vw;
  }
  
  @media (min-width: 400px) {
    object-fit: cover;
    max-width: 40vw;
    min-height: 40vw;
    max-height: 40vw;
  }

  @media (min-width: 750px) {
    object-fit: cover;
    max-width: 400px;
    min-height: 400px;
    max-height: 400px;
  }

  @media (min-width: 1316px) {
    max-width: 500px;
    min-height: 500px;
    max-height: 500px;
  }

  @media (min-width: 1382px) {
    max-width: 565px;
    max-height: 565px;
    min-height: 565px;
    object-position: bottom;
  }
`;

export const Header = styled.h2`
  font-family: "Now Bold";
  font-weight: 900;
  line-height: 1.2;
  color: #000;
  margin: 20px 0;
  text-align: left;
  font-size: 28px;

  @media (min-width: 500px) {
    font-size: 44px;
    margin: 0 auto 80px;
    margin-left: 0;
  }

  @media (min-width: 1210px) {
    font-size: 59px;
  }
`;

export const Text = styled.p`
  margin: 0;
  font-family: Now;
  font-size: 20px;
  font-weight: 300;
  line-height: 1.65;
  text-align: left;
  color: #747474;

  @media (min-width: 500px) {
    font-size: 26px;
  }
`;
